import { IconButton } from "@mui/material"
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from "react";
import Delete from "./modals/Delete";
import Upgrade from "./modals/Upgradde";
import Edit from "./modals/Edit";


export default function SbcAtts(props) {
  const attendee = props.att
  const [color, setColor] = useState('')
  const [font, setFont] = useState('#131b26')
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const [openUpgrade, setOpenUpgradee] = useState(false);
  const handleOpenUpgrade = () => setOpenUpgradee(true);
  const handleCloseUpgrade = () => setOpenUpgradee(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);




  useEffect(() => {
    if (props.att.status === 'cancelled') {
      setColor('#E8160C')
      setFont('#ffffff')
    }
  }, [props.att])

  return (
    <>
      <tr key={props.index} style={{ backgroundColor: color, color: font }}>
        <td>{attendee.name}</td>
        <td>{attendee.lastName}</td>
        <td>{attendee.email}</td>
        <td>{attendee.phone}</td>
        <td>{attendee.address}, {attendee.postcode}</td>
        <td>{attendee.ticketType}</td>
        <td style={{ cursor: 'pointer' }}><IconButton onClick={handleOpenDelete}><EventBusyIcon color='primary' fontSize="large" /></IconButton></td>
        <td style={{ cursor: 'pointer' }}><IconButton onClick={handleOpenUpgrade}><ChangeCircleIcon color='primary' fontSize="large" /></IconButton></td>
        <td style={{ cursor: 'pointer' }}><IconButton><EmailIcon color='primary' fontSize="large" /></IconButton></td>
        <td style={{ cursor: 'pointer' }}><IconButton><PrintIcon color='primary' fontSize="large" /></IconButton></td>
        <td style={{ cursor: 'pointer' }}><IconButton onClick={handleOpenEdit}><EditIcon color='primary' fontSize="large" /></IconButton></td>
      </tr>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {/* DELETE MODAL */}
      <Modal
        open={openDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Delete att={props.att} close={handleCloseDelete} style={props.style} rel={props.rel} userId={props.userId}/>
      </Modal>
      {/* UPGRADE MODAL */}
      <Modal
        open={openUpgrade}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Upgrade att={props.att} close={handleCloseUpgrade} style={props.style} rel={props.rel} event={props.event} userId={props.userId}/>
      </Modal>
      {/* EDIT MODAL */}
      <Modal
        open={openEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Edit att={props.att} close={handleCloseEdit} style={props.style} rel={props.rel} event={props.event} userId={props.userId}/>
      </Modal>



    </>
  )
}