import Footer from "../Footer";
import Header from "../Header";
import '../../styles/sbc.scss';
import logo from '../../images/sbc_logo.webp';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


export default function SbcSelect() {
  const [access, setAccess] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    let id = localStorage.getItem('access')
    setAccess(parseInt(id))
  }, [])


  return (
    <main className="sbc__select">
      <Header />
      <section className='sbc__selection'>
        <img src={logo} alt='Scottish Business Conference' />
        <section className='selection'>
          {access === 1 ? (
          <article className='selection_card'
          onClick={() => navigate('/sbc/tickets')}
          >
            <ConfirmationNumberIcon fontSize='large' color='primary' />
            <strong>Manage Tickets/Pricing</strong>
          </article>

          ) : null}
          <article className='selection_card'
          onClick={() => navigate('/sbc/attendees')}
          >
            <PersonSearchIcon fontSize='large' color='primary' />
            <strong>Manage Attendees</strong>
          </article>
          <article className='selection_card'
          // onClick={() => navigate('/sbc/attendees')}
          >
            <AnalyticsIcon fontSize='large' color='primary' />
            <strong>SBC Dashboard</strong>
          </article>
        </section>
      </section>
      <Footer />
    </main>
  )
}